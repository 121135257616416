import React, { useState, useEffect } from 'react';
import { Col, Row, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import NewsHero from '../shared/NewsHero/NewsHero';
import NewsNav from "../shared/Navs/NewsNav";
import PageTitle from "../shared/PageTitle/PageTitle";
import axios from 'axios';
import DOMPurify from 'dompurify';
import './SingleHenricoNow.scss';
import _ from 'lodash';

const pageTitle = "Henrico NOW";

const HenricoNowSingleArticle = (props) => {
    const location = useLocation();
    let queryData;
    let queryType;

    if (location.state && location.state.articleId !== undefined) {
        queryData = location.state.articleId;
        queryType = 'id';
    } else {
        queryData = location.pathname.split("/").slice(-1)[0];
        queryType = 'title';
    }

    const [title, setTitle] = useState([]);
    const [featuredImage, setFeaturedImage] = useState();
    const [railImages, setRailImages] = useState();
    const [date, setDate] = useState();
    const [copy, setCopy] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
            axios.post(`${process.env.REACT_APP_NEWS_URL}/henrico-now-articles/single`, {
                queryData,
                queryType
            })
                .then(res => {
                    setLoading(true);
                    setTitle(res.data.henricoNowArticle && res.data.henricoNowArticle.title);
                    setFeaturedImage(res.data.henricoNowArticle && res.data.henricoNowArticle.image_featured_large);
                    setRailImages(res.data.henricoNowArticle && res.data.henricoNowArticle.assets);
                    setDate(res.data.henricoNowArticle && res.data.henricoNowArticle.date);
                    setCopy(res.data.henricoNowArticle && res.data.henricoNowArticle.copy);
                    setLoading(false);
                })
    }, []);

    DOMPurify.addHook('afterSanitizeAttributes', function(node) {
        if ('target' in node) {
            node.setAttribute('target','_blank');
            node.setAttribute('rel', 'noopener noreferrer');
        }
        if (!node.hasAttribute('target')
            && (node.hasAttribute('xlink:href')
                || node.hasAttribute('href'))) {
            node.setAttribute('xlink:show', 'new');
        }
    });

    const cleanHtmlCopy = DOMPurify.sanitize(copy);

  const renderRailImages = () => {
    const imgArr = [];

    _.each(railImages[0], function(assetGroup,assetGroupKey) {
      _.each(assetGroup[0], function(assets,assetsKey) {
        imgArr.push(assets);
      });
    });

    return <div class="rail-images">
      {imgArr.map(img => (
        <img src={img} alt={img} />
      ))}
    </div>;
  }

    if (loading) {
        return <h2>Loading...</h2>;
    }

    return (
        <div>
            <NewsHero />
            <Container>
                <NewsNav />
                <PageTitle title={pageTitle} />
                <Row>
                    <Col xs={12} sm={12} md={8}>
                        <Row>
                            <Col>
                                <p className="paragraph-header--blue">{title}</p>
                            </Col>
                        </Row>
                        <Row className="henrico-now-post-date-row">
                            <Col>
                                <p>{date}</p>
                            </Col>
                        </Row>
                        <Row className="single-copy-row">
                            <Col>
                                <p dangerouslySetInnerHTML={{ __html: cleanHtmlCopy }} />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={12} md={4}>
                        {featuredImage && <Row>
                            <Col>
                                <img className="img-fluid" src={featuredImage} alt={title} />
                            </Col>
                              </Row>
                      }

                                {railImages && <Row>
                                  <Col>
                                    {renderRailImages()}
                                  </Col>
                                  </Row>
                                }
                                            </Col>
                                              </Row>
                                                </Container>
        </div>
    )
}

export default HenricoNowSingleArticle;
