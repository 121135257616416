import { Col, Container, Row} from "react-bootstrap";
import React from "react";
import NewsInsights from '../shared/NewsInsights/NewsInsights';
import LogosImage from './images/advanced-manufacturing-logos.png';
import LogosImage2 from './images/advanced-manufacturing-logos-2.png';
import "./TargetSectors.scss";
import Quote from "../shared/Quote/Quote.js";
import DataCard from '../shared/DataCard/DataCard';
import TargetSectorsHero from './TargetSectorsHero';
import TargetSectorsNav from '../shared/Navs/TargetSectorsNav';
import PageTitle from '../shared/PageTitle/PageTitle';
import './AdvancedManufacturing.scss';

const pageTitle = 'Henrico Manufacturing';

const quoteData = {
    copy: "“Our U.S. expansion project is an exciting opportunity for future growth. We explored U.S. locations and selected Virginia and Henrico among several that were considered. Not only is this location in close proximity to most of our U.S. customers – it provides us with a foundation for future strategic growth and expansion.”",
    signature: "Lionel Genix, President and CEO, Seppic, Inc., and Manager, Polykon Manufacturing"
}

const cards = [
    {
        titleLine1: "Since",
        titleLine2: "1611",
        text: 'Henrico has been a center for commerce and trade.',
        categoryColor: '66A7CF',
    },
    {
        titleLine1: "Reach Customers Quickly",
        titleLine2: "",
        text: 'More than 55% of the U.S. population and major manufacturing sites are located within 750 miles of Henrico.',
        categoryColor: '66A7CF',
    },
    {
        titleLine1: "140M Pounds of Cargo",
        titleLine2: "",
        text: 'are handled annually at Richmond International Airport.',
        categoryColor: '66A7CF',
    }
]

const AdvancedManufacturing = () => {
    return (
        <div>
            <TargetSectorsHero />
            <Container>
                <TargetSectorsNav />
                <PageTitle title={pageTitle} />
                <Row>
                    <Col lg={12} sm={12} md={12} xl={12}>
                    <p>Eager to build your product in a place that can boost your bottom line and power your future growth? Whether you’re in the food and beverage or chemical industry, securing a Henrico manufacturing plant makes all the difference to your business longevity. Take it from the businesses that have chosen to establish a footprint here – like world-packaging leader <a href="https://www.westrock.com/" target="_blank" rel="noopener noreferrer">WestRock</a>, French pharmaceutical giant <a href="https://www.fareva.com/en" target="_blank" rel="noopener noreferrer">Fareva</a>, and the nation’s largest Coke bottler, <a href="https://www.cokeconsolidated.com/" target="_blank" rel="noopener noreferrer">Coca-Cola Consolidated</a> – you’re going to love what Henrico has to offer.<br/><br/>Eager to relocate or expand your facility? Download this convenient <a href="https://henrico-eda-site-assets.s3.amazonaws.com/why-henrico/HEN22002_Manufacturing+Services_8.5x11_FINAL+0920.pdf" target="_blank" rel="noopener noreferrer">resource</a> to easily reference the Henrico advantage. </p>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/target-sectors/Scroll+Group+4.png" width="100%"/>
                    <br/><br/>
                    <p><h3>Unbeatable Location</h3></p>
                    <p>We’re optimally located in the middle of the Eastern seaboard, which means a facility in Henrico can be your cure for supply chain issues and springboard to logistics success.<br></br><br></br><ul><li>More than 55% of U.S. consumer markets are located within 750 miles. </li><li>Washington, D.C., the Blue Ridge Mountains, and Virginia’s coastline are all less than a two-hour drive away.</li><li>Henrico is home to <a href="https://www.henrico.com/white-oak/quick-facts" target="_blank" rel="noreferrer noopener">White Oak Technology Park</a>, a master-planned, publicly owned industrial park with extensive power.</li></ul>With a strategic location like ours, it’s no wonder that Henrico was recognized by fDi Intelligence as the second-best place in the world for transport and warehousing strategy.</p>
                    </Col>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/target-sectors/Coca-Cola_maleworker_10.png" width="100%"/>
                    <br/><br/>
                    <p><h3>Available Henrico Manufacturing Facilities</h3></p>
                    <p>Are you an industrial manufacturer establishing your business presence? Looking to grow your business footprint? Henrico has a large inventory of available sites ideal for factory plants and laboratory facilities. In recent years, we helped <a href="https://www.seppic.com/en/about-us/production-sites/polykon-united-states" target="_blank" rel="noreferrer noopener">Polykon Manufacturing</a>, a joint venture between Seppic and Schülke, set up its first North American manufacturing operation. We also assisted <a href="https://www.henrico.com/news/press-releases-and-announcements/2021-09-27/cocacola" target="_blank" rel="noreferrer noopener">Coca-Cola Consolidated in expanding its production</a> and distribution facility. If you are interested in securing the best site for your business, <a href="https://www.henrico.com/about-us/contact-us" target="_blank;">reach out</a> to us so that we can offer expert advice about our area and guide you through the regulatory process.</p>
                    </Col>                    
                </Row>                
                <Row>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/target-sectors/DSC_0430a_.png" width="100%"/>
                    <br/><br/>
                    <p><h3>World-Class Infrastructure</h3></p>
                    <p>Henrico is your connection to the rest of the world because we make manufacturing and distribution virtually seamless. Whether you want to send your merchandise by air, water, rail, or road, a presence here means you can get your products to market more quickly. Take advantage of:<br></br><br></br><ul><li><a href="https://flyrichmond.com/" target="_blank" rel="noreferrer noopener">Richmond International Airport</a>, one of the nation’s busiest air cargo facilities and a designated Foreign Trade Zone.</li><li>The <a href="https://www.portofvirginia.com/" target="_blank" rel="noreferrer noopener">Port of Virginia</a>, a global trade gateway and set to be the deepest and widest port on the East Coast by 2024.</li><li>Three Class I rail providers, including <a href="https://www.csx.com/" target="_blank" rel="noreferrer noopener">CSX</a>, <a href="http://www.nscorp.com/content/nscorp/en.html" target="_blank" rel="noreferrer noopener">Norfolk Southern</a>, and <a href="http://buckinghambranch.com/" target="_blank" rel="noreferrer noopener">Buckingham Branch Railroad</a>.</li></ul></p>
                    </Col>
                    <Col lg={6} sm={6} md={6} xl={6}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/target-sectors/iStock-1330234595.png" width="100%"/>
                    <br/><br/>
                    <p><h3>Low Taxes in Henrico</h3></p>
                    <p>We have a long history of working with our local manufacturers to give them the best foundation for success. We reduced our machinery and tools tax rate to $0.24/$100 of assessed value, the lowest effective rate in Central Virginia. Also, our real estate tax rate has only decreased since 1978 and now stands at $0.85/$100 of assessed value. Build a Henrico manufacturing plant and rest easy knowing that you can lower your operating cost while increasing your ROI.</p>
                    </Col>                                       
                </Row>                           
                {/* <Quote copy={quoteData.copy} signature={quoteData.signature} /> */}
                <NewsInsights />    
            </Container>
        </div>
    );
    };

export default AdvancedManufacturing;