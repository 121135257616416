import { Col, Container, Row } from "react-bootstrap";
import React from "react";
import "./CorporateRegionalHeadquarters.scss";
import NewsInsights from '../shared/NewsInsights/NewsInsights';
import LogosImage from './images/headquarter-logos.png';
import TargetSectorsHero from './TargetSectorsHero';
import TargetSectorsNav from '../shared/Navs/TargetSectorsNav';
import PageTitle from '../shared/PageTitle/PageTitle';
import "./TargetSectors.scss";

const pageTitle = 'Corporate and Regional Headquarters';

// const paragraphStyles = {
//     fontWeight: 600
// };

const CorporateRegionalHeadquarters = () => {
    return (
        <div>
            <TargetSectorsHero />
            <Container>
                <TargetSectorsNav />
                <PageTitle title={pageTitle} />
                <Row>
                    <Col lg={12} sm={12} md={12} xl={12}>
                    <p>
                        Henrico is the perfect home for corporate and regional headquarters from a variety of industries. 
                        CEOs and their staffs are attracted to our large pool of qualified workers, affordable cost of living, and high quality of life. 
                        Establishing a headquarters in Henrico is a strategic move, enhancing success on all levels and creating positive, 
                        impactful change for the future.
                    </p>
                    <p>
                        <b style={{fontWeight: "bold"}}>Six Fortune 1000 firms</b> are headquartered in Henrico: Altria Group, Genworth Financial, Markel Corporation, ASGN, Arko Corp. and Brink’s. 
                        Henrico is also home to national, international, regional and divisional headquarters for a diverse set of companies 
                        such as Allianz Global Assistance, Fareva, Patient First, Kroger and McKesson Medical-Surgical.
                        Discover what's attracting these industry leaders to our locality. Download our convenient <a href="https://henrico-eda-site-assets.s3.amazonaws.com/target-sectors/HEN23001_Corporate+Services_8.5x11_v4.pdf" target="_blank;" title="Corporate Services Brochure PDF">Corporate Services brochure</a> now.
                    </p>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <img className="logo-space" alt="logos" src={LogosImage} width="100%" />
                </Row>
                <NewsInsights />
            </Container>
        </div>
    );
    };

export default CorporateRegionalHeadquarters;
